import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { useSessionStorage } from 'react-use'

import InputLoading from '../components/InputLoading'

const Loader = ({ SubComp, ...props }) => {
  const [done, setDone] = useSessionStorage('RE_LAB:HomePageLoaded')
  return (
    <>
      <SubComp loaded={done} {...props} />
      <AnimatePresence>
        {!done && (
           <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <InputLoading onComplete={() => setDone(true)} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

const withLoading = SubComp => props => <Loader SubComp={SubComp} {...props} />

export default withLoading
