import React from 'react'
import SVG from '../SVG'

const ReColon = (props) => {
  return (
    <SVG viewBox="0 0 266.8 136" {...props}>
      <g fill="currentColor">
        <path d="M71.41,124.09,49,76.28a.63.63,0,0,0-.64-.48h-23a.57.57,0,0,0-.64.64v47.17a1.41,1.41,0,0,1-1.59,1.59H11a1.41,1.41,0,0,1-1.59-1.59V14.88A1.41,1.41,0,0,1,11,13.28H54a34.52,34.52,0,0,1,16.87,4A28.16,28.16,0,0,1,82.12,28.47a33.63,33.63,0,0,1,4,16.63q0,11.19-5.84,18.95A28.63,28.63,0,0,1,64.06,74.52c-.43.22-.54.48-.32.8l23.18,48a1.87,1.87,0,0,1,.16.8c0,.74-.43,1.11-1.28,1.11H73.17A1.75,1.75,0,0,1,71.41,124.09ZM24.72,27.2V63a.57.57,0,0,0,.64.64h26.7q8.47,0,13.6-5t5.11-13.35q0-8.48-5.11-13.59t-13.6-5.11H25.36A.57.57,0,0,0,24.72,27.2Z"/>
        <path d="M180.13,26.56H123.37a.56.56,0,0,0-.64.64V61.57a.56.56,0,0,0,.64.64h48.08a1.42,1.42,0,0,1,1.6,1.6V73.88a1.42,1.42,0,0,1-1.6,1.6H123.37a.57.57,0,0,0-.64.64v35.17a.56.56,0,0,0,.64.64h56.76a1.42,1.42,0,0,1,1.6,1.6v10.08a1.41,1.41,0,0,1-1.6,1.59H109a1.41,1.41,0,0,1-1.6-1.59V14.88a1.42,1.42,0,0,1,1.6-1.6h71.15a1.42,1.42,0,0,1,1.6,1.6V25A1.42,1.42,0,0,1,180.13,26.56Z"/>
        <path d="M233.82,81A22.21,22.21,0,1,0,256,103.2,22.2,22.2,0,0,0,233.82,81Zm0,35.19a13,13,0,1,1,13-13A13,13,0,0,1,233.82,116.19Z"/>
        <circle cx="233.82" cy="38.6" r="22.2"/>
      </g>
    </SVG>
  )
}

export default ReColon
