import React from 'react'
import SVG from '../../../components/SVG'

const Bang = (props) => {
  return (
    <SVG viewBox="0 0 80 100" {...props}>
      <path
        fill="currentColor"
        d="M30.68,28.19l2.58-2a3.2,3.2,0,0,0,.91-1.09L37.92,18A3.32,3.32,0,0,1,40,16.29l6.38-1.72a3.3,3.3,0,0,0,1.92-1.41l.66-1a3.3,3.3,0,0,1,2.78-1.53h1.16a3.29,3.29,0,0,1,2.89,1.72v0A3.27,3.27,0,0,0,58.06,14l2.14.41a3.28,3.28,0,0,1,2.28,1.67l0,0a3.3,3.3,0,0,1-.79,4.09L60.2,21.45A3.31,3.31,0,0,0,59,23.73l-.14,1.87A3.21,3.21,0,0,0,59,26.79l.89,3a3.29,3.29,0,0,1-.24,2.46l-4.73,9.07a3.36,3.36,0,0,0-.33,1L51.1,65.46A3.33,3.33,0,0,1,50.41,67l-9.26,11.5a3.3,3.3,0,0,0-.71,1.75l-.92,9.54a3.29,3.29,0,0,1-3.27,3h0A3.29,3.29,0,0,1,33,90.2l-1.22-5.68a3.33,3.33,0,0,0-1.2-1.91L24.5,77.88a3.17,3.17,0,0,1-.95-1.17L17.44,64a3.28,3.28,0,0,1-.3-1.9l2.3-15.88A3.15,3.15,0,0,1,19.91,45l10-15.91A3.21,3.21,0,0,1,30.68,28.19Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </SVG>
  )
}

export default Bang
