import React from 'react'
import SVG from '../../../components/SVG'

const Bang = (props) => {
  return (
    <SVG viewBox="0 0 80 100" {...props}>
      <path
        fill="currentColor"
        d="M65.12,31.06h0A16.06,16.06,0,0,0,41,29.32l-1,.94-.11-.11-.84-.83a16.06,16.06,0,0,0-24.17,1.74h0a16.07,16.07,0,0,0,1.33,20.86l14.23,14.6v.12L36.66,73c.88.9,2.05,2.32,3.23,2.32s2.34-1.42,3.23-2.32l6.22-6.39v-.25l.11.25L63.79,51.92A16.07,16.07,0,0,0,65.12,31.06Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </SVG>
  )
}

export default Bang
