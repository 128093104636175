import { Container, Flex, ListItem, UnorderedList } from '@chakra-ui/layout'
import React, { useEffect, useState } from 'react'
import { useHarmonicIntervalFn } from 'react-use'
import { motion } from 'framer-motion'
import range from 'lodash/range'

import Box from './Box'
// import BackgroundImage from './BackgroundImage'
import SVG from './SVG'

// import reColon from '../images/re-colon.svg'
import { responsive } from '../contexts/responsive'
import AnimateReColon from './AnimateReColon'

const MotionBox = motion(Box.Absolute)

const items = [
  'communication',
  'survey',
  'strategy',
  'creative',
]

const precount = 2
const aftercount = 2

const LAB = props => (
  <SVG viewBox="0 0 106 43" verticalAlign="initial" {...props}>
    <path d="M.46,42.37V.63A.54.54,0,0,1,1.08,0H5.74a.54.54,0,0,1,.62.61v37c0,.17.08.25.24.25H27.78a.54.54,0,0,1,.61.61v3.87a.54.54,0,0,1-.61.61H1.08A.54.54,0,0,1,.46,42.37Z" fill="currentColor" />
    <path d="M62.82,42.49l-3.4-11a.26.26,0,0,0-.25-.19H44.34a.24.24,0,0,0-.24.19l-3.44,11a.71.71,0,0,1-.73.49H35c-.46,0-.62-.22-.5-.67L47.91.51A.7.7,0,0,1,48.64,0h6.14a.72.72,0,0,1,.74.49l13.56,41.8.06.24c0,.29-.18.43-.55.43h-5A.71.71,0,0,1,62.82,42.49Zm-17.16-16a.33.33,0,0,0,.22.09h11.7a.29.29,0,0,0,.21-.09.18.18,0,0,0,0-.22L51.89,7.38a.2.2,0,0,0-.36,0L45.63,26.3A.18.18,0,0,0,45.66,26.52Z" fill="currentColor" />
    <path d="M99,21q6.51,2.76,6.51,10.25,0,5.64-3.75,8.71A14.87,14.87,0,0,1,92,43H76.75a.54.54,0,0,1-.61-.61V.63A.54.54,0,0,1,76.75,0H91.54q6.39,0,9.92,2.91t3.52,8.5q0,6.63-6,9.15C98.83,20.7,98.83,20.82,99,21ZM82,5.36v13c0,.16.08.24.25.24h9.26a8.27,8.27,0,0,0,5.62-1.78,6.07,6.07,0,0,0,2.06-4.84,6.36,6.36,0,0,0-2.06-5,8.15,8.15,0,0,0-5.62-1.84H82.28A.22.22,0,0,0,82,5.36ZM97.59,35.89a6.85,6.85,0,0,0,2-5.24,6.81,6.81,0,0,0-2.08-5.25,8.08,8.08,0,0,0-5.71-1.94H82.28a.22.22,0,0,0-.25.25V37.58a.22.22,0,0,0,.25.25H92A7.78,7.78,0,0,0,97.59,35.89Z" fill="currentColor" />
  </SVG>
)

const TheList = () => (
  <UnorderedList listStyleType="none" marginInlineStart="0" lineHeight="1.33" mb="0.15em">
    {items.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
  </UnorderedList>
)

const KeyowrdsList = ({ y, transitionDuration, ...props }) => (
  <MotionBox
    style={{ y: 0 }}
    animate={{ y }}
    transition={{ duration: transitionDuration }}
    {...props}
  >
    {range(precount).map(key => <TheList key={`pre-${key}`} />)}
    <LAB width="14vmin" mx="0.1em" my="0.25em" display="block" />
    {range(aftercount).map(key => <TheList key={`after-${key}`} />)}
  </MotionBox>
)

const totalLength = items.length * (precount + aftercount) + 1
const count = (items.length * precount + 1)
// const easeIn = t => t ** 2
const easeInOut = t => t < .5 ? 4 * (t ** 3) : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
const interpolate = (a, b, x, y) => (c) => (y - x) * easeInOut((c - a) / (b - a)) + x
const inte = interpolate(0, count, 100, 500)

const InputLoading = ({ onComplete }) => {
  const [active, setActive] = useState(0)
  const [done, setDone] = useState(0)
  const [complete, setComplete] = useState(0)
  const y = `-${active / totalLength * 100}%`

  const tickDuration = inte(active)
  const transitionDuration = (tickDuration) / 1000
  // useEffect(() => {
  //   const timer = setTimeout(() => setDone(1), totalTimeout)
  //   return () => clearTimeout(timer)
  // }, [])
  useHarmonicIntervalFn(() => setActive(t => {
    if (t < count) return t + 1
    return t
  }), done ? null : tickDuration)
  useEffect(() => {
    if (active + 1 === count) setDone(1)
  }, [active])
  useEffect(() => {
    if (done) {
      const timer = setTimeout(() => setComplete(1), 1000)
      return () => clearTimeout(timer)
    }
  }, [done])
  useEffect(() => {
    if (complete) {
      const timer = setTimeout(onComplete, 500)
      return () => clearTimeout(timer)
    }
  }, [complete, onComplete])
  return (
    <Box.Fixed
      left="0"
      right="0"
      top="0"
      height="100%"
      bg="black"
      color="white"
      zIndex="popover"
    >
      <Container pt="8vh" transition="opacity 1s" maxWidth="97vmin">
        <Flex position="relative" px="10%" py={responsive('28vh', '33vh')} overflow="hidden">
          <Box width="13vmin">
            <Box py="13%">
              {/* <BackgroundImage ratio={96 / 45} src={reColon} /> */}
              <AnimateReColon />
            </Box>
          </Box>
          <Box
            flex="1"
            mx="1vmin"
            border="1px solid"
            fontSize="8vmin"
            px="1vmin"
            fontWeight="500"
          >
            <KeyowrdsList color="#4D4D4D" y={y} transitionDuration={transitionDuration} />
            <Box.Relative
              height="100%"
              overflow="hidden"
              position="relative"
              bg="black"
            >
              <KeyowrdsList y={y} transitionDuration={transitionDuration} />
            </Box.Relative>
          </Box>
        </Flex>
      </Container>
    </Box.Fixed>
  )
}

export default InputLoading
