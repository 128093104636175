import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from "@reach/router"
import { Center, Spinner, Stack, useBreakpointValue, Text, VStack } from '@chakra-ui/react';
import { navigate } from 'gatsby';

import Box from '../../components/Box'
import withLoading from '../../hoc/withLoading';
import useHeader from '../../contexts/header/useHeader';
import Cover from './Cover';
import FullpageContext from './fullpageContext';
import PageTemplate from './PageTemplate';
import Cooperate from './Cooperate';
import Imagination from './Imagination';
import Value from './Value';
import Start from './Start';

import AnimateTitle from './AnimateTitle';
import { responsive } from '../../contexts/responsive';
import useLottieFiles from './useLottieFiles';

const scrollingSpeed = 1000

let silentMoving
let justSilentMoved
// let animationFinished
let fpApi

const Jump = ({ active }) => {
  // useEffect(() => {
  //   if (active) {
  //     navigate(`/base`)
  //   }
  // }, [active])
  return null
}

const Page = ({ children, index, loaded, totalPages }) => {
  const lottieLoaded = useRef()
  const { currentPage, currentDirection } = useContext(FullpageContext)
  const active = useMemo(() => (typeof loaded !== 'boolean' || loaded) && index === currentPage, [currentPage, index, loaded])
  const dim = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  })
  useEffect(() => {
    if (active && !lottieLoaded.current) {
      if (children.props.lottieJson) {
        const files = Object.values(children.props.lottieJson).map(v => v[dim])
        files.reduce((prev, { publicURL }) => prev.then(() => fetch(publicURL)), Promise.resolve())
        lottieLoaded.current = true
      }
    }
  }, [active, index, totalPages, dim])
  return useMemo(() => (
    <div className="section" style={{ overflow: 'hidden' }}>
      {React.cloneElement(children, { active, index, direction: currentDirection })}
    </div>
  ), [active, children])
}

const pages = [
  {
    title: "cooperate",
    tagline: "每3個客戶，就有1個再次找我們合作",
    action: "填寫表單：開啟合作",
    trackerName: 'home_collab',
    to: "/contact",
    item: Cooperate,
    anchor: 'cooperate',
  },
  {
    title: "create value",
    tagline: "已經和超過 100 個客戶共創新的溝通價值",
    action: "看作品集：想像合作",
    trackerName: 'home_collection',
    to: "/portfolio",
    item: Value,
    anchor: 'value',
  },
  {
    title: "imagination",
    tagline: `想知道我們源源不絕的愛和創意從何而來？\n讓我們在線為你導覽扎根臺灣的秘密基地！`,
    action: "進入基地：開始探險",
    trackerName: 'home_guide',
    href: '#guide',
    item: Imagination,
    anchor: 'imagination',
  },
]

const silentMoves = {
  guide: {
    down: true
  },
  adventure: {
    up: true
  },
}

const FullPage = ({ children }) => {
  const [jumping, setJumping] = useState(false)
  const { setCurrentStatus } = useContext(FullpageContext)
  const flattern = useMemo(() => children.reduce((all, c) => all.concat(c), []), [children])
  const anchors = useMemo(() => flattern.map(({ props }, i) => i ? props?.anchor || `p${i + 1}` : ''), [flattern])
  return (
    <>
      <ReactFullpage
        licenseKey={process.env.GATSBY_FULLPAGE_JS_KEY}
        scrollingSpeed={scrollingSpeed}
        anchors={anchors}
        afterLoad={() => {
          // animationFinished = false;
          if (silentMoving) {
            silentMoving = false
            justSilentMoved = true
            setTimeout(() => {
              justSilentMoved = false
            }, scrollingSpeed)
          }
        }}
        onLeave={(origin, dest, dir) => {
          if (justSilentMoved) return false

          const target = dest.index

          // if (silentMoving) console.log(origin.anchor, dir, dest.anchor, 'silentMoving')
          if (!silentMoving && silentMoves[origin.anchor]?.[dir]) {
            silentMoving = true
            fpApi.silentMoveTo(target + 1)
            // console.log('request silent move', origin.anchor, '=>', dest.anchor)
            return false
          }
          setCurrentStatus({
            currentPage: target,
            currentDirection: dir,
            currentAnchor: dest.anchor,
          })
          if (dest.isLast) {
            setTimeout(() => {
              navigate('/base')
              setJumping(true)
            }, 700)
            return false
          }
          return true
        }}
        render={({ fullpageApi }) => {
          fpApi = fullpageApi

          return (
            <ReactFullpage.Wrapper>
              {flattern.map((page, i) => <Page key={i + page?.props?.anchor} index={i} totalPages={flattern.length}>{page}</Page>)}
            </ReactFullpage.Wrapper>
          )
        }}
      />
      <AnimatePresence>
        {jumping && (
          <Center
            as={motion.div}
            position="fixed"
            left="0"
            top="0"
            right="0"
            height="100vh"
            bg="blackAlpha.800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <VStack color="white">
              <Spinner colorScheme="whiteAlpha" />
              <Text fontSize="1rem">正前往計畫基地...</Text>
            </VStack>
          </Center>
        )}
      </AnimatePresence>
    </>
  )
}

const IndexPage = ({ loaded }) => {
  const location = useLocation();

  const [{ currentPage, currentDirection, currentAnchor }, setCurrentStatus] = useState({ currentPage: 0, currentAnchor: '' })
  const { headerHeight, setHeaderMode } = useHeader()

  useEffect(() => {
    if (fpApi) {
      const hash = location.hash?.slice(1)
      fpApi.moveTo(hash ?? 0)
    }
  }, [location])
  useEffect(() => {
    setHeaderMode(currentPage > 0 ? 'solid' : 'transparent')
  }, [currentPage, setHeaderMode])
  useEffect(() => {
    return () => setHeaderMode('solid')
  }, [setHeaderMode])
  const lottieJson = useLottieFiles()

  return (
    <Box mt={`-${headerHeight}`}>
      <AnimatePresence>
        {currentPage > 0 && currentPage < 4 && (
          <Box.Fixed
            as={motion.div}
            textAlign="center"
            top="50%"
            left="0"
            right="0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.5 } }}
            exit={{ opacity: 0 }}
            transform={responsive('translateY(-155%)', 'translateY(-120%)')}
          >
            <AnimateTitle delay={currentPage > 1 || currentDirection === 'up' ? 200 : 500}>
              {pages[currentPage - 1]?.title}
            </AnimateTitle>
          </Box.Fixed>
        )}
      </AnimatePresence>
      <FullpageContext.Provider
        value={{
          currentPage,
          currentDirection,
          currentAnchor,
          setCurrentStatus,
          pageTimeout: 500,
          getFullpageApi: () => fpApi,
        }}
      >
        {useMemo(() => (
          <FullPage>
            <Cover loaded={loaded} />
            {pages.map((p, i) => <PageTemplate {...p} key={i} />)}
            <Start anchor="guide" lottieJson={lottieJson} />
            <Start anchor="adventure" textIndex={1} />
            <Jump anchor="jump" />
          </FullPage>
        ), [loaded, lottieJson])}
      </FullpageContext.Provider>
    </Box>
  )
}

export default withLoading(IndexPage)
