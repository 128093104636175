import { Container, Flex, Heading, Text, Stack } from '@chakra-ui/layout'
import React, { createElement, useCallback, useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'

import Button from '../../components/Button'
import Box from '../../components/Box'
import Colon from '../../components/Logos/Colon'
import { responsive } from '../../contexts/responsive'
// import TypeWriter from '../../components/TypeWriter'

const MotionBox = motion(Box)

const PageTemplate = ({
  active,
  tagline,
  action,
  to,
  href,
  onClick,
  trackerName,
  index,
  direction,
  item,
}) => {
  const introDelay = index > 1 || direction === 'up' ? 1.5 : 1
  const getOpacityVariants = useCallback((delay = 0) => ({
    enter: {
      opacity: 1,
      transition: { delay: introDelay + delay }
    },
    leave: { opacity: 0 },
  }), [introDelay])
  return (
    <MotionBox
      height="100%"
      textAlign="center"
      position="relative"
      animate={active ? 'enter' : 'leave'}
      as={Flex}
      alignItems="center"
    >
      {item && createElement(item, { in: active })}
      <Container as={Stack} alignItems="center" minHeight="10em">
        <Text
          as="h4"
          fontSize={responsive('1em', '1.5em')}
          color="custom.gray"
          whiteSpace="pre-wrap"
          mt={responsive('3.25rem', '6rem')}
        >{tagline}</Text>
        <MotionBox pt={responsive('1.5em', '0.5em')} variants={getOpacityVariants(1.25)}>
          <Button.Outline
            to={to}
            href={href}
            onClick={onClick}
            trackerName={trackerName}
            fontSize={responsive('1em', '1.5em')}
            py={responsive('0.375rem', '0.75rem')}
            px={responsive('0.7rem', '2.625rem')}
          >{action}</Button.Outline>
        </MotionBox>
      </Container>
      <motion.div
        style={{
          left: '50%',
          bottom: '5%',
          x: '-50%',
          position: 'absolute',
        }}
        variants={getOpacityVariants(1.7)}
      >
        <motion.div
          animate={{
            y: ['0%', '25%', '0%'],
          }}
          transition={{
            duration: 1,
            ease: "backOut",
            repeat: Infinity,
            repeatDelay: 2
          }}
        >
          <Colon color="black" width="1.125em" />
        </motion.div>
      </motion.div>
    </MotionBox>
  )
}

export default PageTemplate
