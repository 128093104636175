import { useEffect, useRef, useState } from 'react'

const useTextTransition = (text, {
  duration = 500,
  delay = 0,
  onLeaveEnd = () => {},
  onEnterEnd = () => {},
}) => {
  const [aniTitle, setAnitile] = useState('')
  const [leaving, setLeaving] = useState()
  const prevText = useRef()
  const enterTask = useRef()
  useEffect(() => {
    let itv
    const cachedText = prevText.current
    const task = () => {
      setAnitile(t => {
        const curLength = t.length
        if (t.length > 0) {
          itv = setTimeout(task, duration / (cachedText.length + 1))
          return cachedText.substring(0, curLength - 1)
        } else {
          onLeaveEnd()
          setLeaving(false)
        }
        return t
      })
    }
    if (cachedText) {
      setLeaving(true)
      itv = setTimeout(task)
    }
    prevText.current = text
    return () => {
      if (enterTask.current) clearTimeout(enterTask.current)
      clearTimeout(itv)
    }
  }, [text])
  useEffect(() => {
    if (text && !leaving && aniTitle.length === 0) {
      const task = () => {
        setAnitile(t => {
          const curLength = t.length
          if (curLength < text.length) {
            enterTask.current = setTimeout(task, duration / (text.length + 1))
            return text.substring(0, curLength + 1)
          }
          onEnterEnd()
          return t
        })
      }
      enterTask.current = setTimeout(task, delay)
    }
  }, [text, leaving, aniTitle.length])

  return aniTitle || ''
}

export default useTextTransition
