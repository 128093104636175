import React from 'react'
import SVG from '../../../components/SVG'

const Bang = (props) => {
  return (
    <SVG viewBox="0 0 80 100" {...props}>
      <path
        fill="currentColor"
        d="M48.75,24l3.72-4.12-.28,5.54A11.79,11.79,0,0,0,64.57,37.81l5.54-.28L66,41.25a11.78,11.78,0,0,0,0,17.5l4.12,3.72-5.54-.28A11.79,11.79,0,0,0,52.19,74.57l.28,5.54L48.75,76a11.78,11.78,0,0,0-17.5,0l-3.72,4.12.28-5.54A11.79,11.79,0,0,0,15.43,62.19l-5.54.28L14,58.75a11.78,11.78,0,0,0,0-17.5L9.89,37.53l5.54.28A11.79,11.79,0,0,0,27.81,25.43l-.28-5.54L31.25,24A11.78,11.78,0,0,0,48.75,24Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </SVG>
  )
}

export default Bang
